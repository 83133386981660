<template>
  <card :id="cardId"/>
</template>

<script>
export default {
  components: {
    card: () => import('./card')
  },
  params: {
    id: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
    cardId() {
      if (this.id) return this.id;
      if (this.$route.params.id) return this.$route.params.id;
    }
  },
}
</script>